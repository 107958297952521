import React from "react";
import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import PropTypes from 'prop-types';
import { images } from "../../data/carousel_data";
import Card from "./Card.jsx";

const cards = images.map((image, index) => ({
  key: index,
  content: <Card imagen={image.url} title={image.title} description={image.description} tags={image.tags} />
}));

var default_props = {
  cards: cards,
  height: "0px",
  width: "50%",
  margin: "0 auto",
  offset: 2,
  showArrows: false,
  cardWidth: 200 // Assuming cardWidth is a required prop
};

function CarouselModule(props) {
  props = { ...default_props, ...props }; // Combine default and provided props
  const [goToSlide, setGoToSlide] = useState(null);
  const [cardsState, setCardsState] = useState(props.cards); // Use combined props

  useEffect(() => {
    const newCards = props.cards.map((element, index) => ({
      ...element,
      onClick: () => setGoToSlide(index)
    }));
    setCardsState(newCards);
  }, [props.cards]);

  // Define the offsetFn function
  const offsetFn = (offsetFromCenter) => {
    // Calculate the scale based on the distance from the center
    const scale = 1 - Math.abs(offsetFromCenter) * 0.15; // Decrease scale by 15% for each step from the center

    // Calculate the opacity to decrease as the card gets further from the center
    const opacity = Math.max(1 - Math.abs(offsetFromCenter) * 0.15, 0.1); // Decrease opacity, but not less than 10%

    // Calculate the Z translation to move further cards back
    const translateZ = -Math.abs(offsetFromCenter) * 30; // Move back 30px for each step from the center

    // Calculate the horizontal offset to make cards "poke out"
    const horizontalOffset = 0.35 * props.cardWidth * offsetFromCenter;

    return {
      opacity,
      transform: `scale(${scale}) translateZ(${translateZ}px)`,
      left: `${horizontalOffset}px`,
    };
  };

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={cardsState}
        goToSlide={goToSlide}
        offsetRadius={props.offset}
        showNavigation={props.showArrows}
        animationConfig={config.gentle}
        offsetFn={offsetFn}
      />
    </div>
  );
}

// Define propTypes for the component
CarouselModule.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number.isRequired,
    content: PropTypes.node.isRequired
  })).isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  offset: PropTypes.number,
  showArrows: PropTypes.bool,
  cardWidth: PropTypes.number
};

// Set default props
CarouselModule.defaultProps = default_props;

export default CarouselModule;
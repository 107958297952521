import img_1 from "./images/ai_img_1.png"
import img_2 from "./images/ai_img_2.png"
import img_3 from "./images/ai_img_3.png"



export const images = [
  { 
    url: (img_1),
    title: "Title 0", 
    description: "Description 0", 
    tags: ["Machine Learning", "Edge Computing"] 
  },
  { 
    url: (img_2),
    title: "Title 1", 
    description: "Description 1",
    tags: ["Data Science", "AI"]
  },
  { 
    url: (img_3),
    title: "Title 2", 
    description: "Description 2", 
    tags: ["Web Development", "React"]
  },
  { 
    url: (img_2),
    title: "Title 1", 
    description: "Description 1",
    tags: ["Data Science", "AI"]
  },
  { 
    url: (img_3),
    title: "Title 2", 
    description: "Description 2", 
    tags: ["Web Development", "React"]
  },
];
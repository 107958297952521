
export const academicData = [
    {
      "year": "2015",
      "title": "Bachelor of Science in Computer Science",
      "description": "Graduated with honors from [University Name] with a Bachelor of Science in Computer Science."
    },
    {
      "year": "2017",
      "title": "Master of Science in Data Science",
      "description": "Earned a Master of Science in Data Science from [University Name]."
    },
    {
      "year": "2017",
      "title": "Master of Science in Data Science",
      "description": "Earned a Master of Science in Data Science from [University Name]."
    },
    {
      "year": "2017",
      "title": "Master of Science in Data Science",
      "description": "Earned a Master of Science in Data Science from [University Name]."
    },
    {
      "year": "2017",
      "title": "Master of Science in Data Science",
      "description": "Earned a Master of Science in Data Science from [University Name]."
    },
    {
      "year": "2017",
      "title": "Master of Science in Data Science",
      "description": "Earned a Master of Science in Data Science from [University Name]."
    },
    {
      "year": "2017",
      "title": "Master of Science in Data Science",
      "description": "Earned a Master of Science in Data Science from [University Name]."
    }
  ];
import React, { useState } from 'react';
import './timeline.css'; // make sure to create this CSS file
import { academicData } from '../../data/timeline_data';

function Timeline() {
  const [hoverIndex, setHoverIndex] = useState(null); 
  const handleHover = (index) => {
    setHoverIndex(index);
  };

  return (
    <div className="timeline-wrapper">
      <div className="timeline-container">
        {academicData.map((entry, index) => (
          <div className="timeline-entry" key={index}>
            <div className="timeline-year">{entry.year}</div>
            <div className="timeline-icon">
              <div className="timeline-dot"></div>
            </div>
            <div className="timeline-info">
              <h3>{entry.title}</h3>
              <p>{entry.description}</p>
            </div>
          </div>
        ))}
        <div className="timeline-line"></div>
      </div>
      <div className="timeline-fade-left"></div>
      <div className="timeline-fade-right"></div>
    </div>
  );
}

export default Timeline;
import React, { useState } from "react";
import './App.css';
import Timeline from './components/timeline/Timeline';
import CarouselModule from './components/carousel/Carousel';


const NAME = "Mustafa Çağlar";
const WELCOME_MESSAGE = "Hey there, looking for some cool tech stuff and some even cooler services. Pick me";

const App = () => {
  const [showEmail, setShowEmail] = useState(false);
  const revealEmail = () => {
    setShowEmail(!showEmail);
  };

  const encodedUser = btoa('hello.mus');
  const encodedDomain = btoa('me.com');

  const getEmailAddress = () => {
    const user = atob(encodedUser);
    const domain = atob(encodedDomain);
    return `${user}@${domain}`;
  };

  //<p className="linkedin-badge badge-base LI-profile-badge" data-locale="en_GB" data-size="small" data-theme="dark" data-type="VERTICAL" data-vanity="mustafacaglar" data-version="v1"></p>              
  return (
    <div>
      <div className="content">
        <div className="left">
          <p className="nameHeader">{NAME}</p>
          <p className="welcome">{WELCOME_MESSAGE}</p>


      <button id="getInTouchButton" onClick={revealEmail}>Get in Touch</button>
      {showEmail && (
        <p className="email-address">{getEmailAddress()}</p>
      )}

        </div>
        <div className="right">
          <CarouselModule />
        </div>
      </div>
      <div className="bottom-half">
        <Timeline />
      </div>
    </div>
  );
}

export default App;              
import React from "react";
import "./ui.css";

const LargeButton = ({ label, handleClick }) => {
    return (
      <button className="btn btn-primary" onClick={handleClick}>
        {label}
      </button>
    );
  };

export default LargeButton;
import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import LargeButton from '../ui/Buttons'

function Card({ tags, imagen, title, description }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });

  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className={Styles.tags}>
        {tags.map((tag, index) => (
          <span key={index} className={Styles.tag}>{tag}</span>
        ))}
      </div>
      <img src={imagen} alt="" />
      <h2>{title}</h2>
      <p>{description}</p>
      <div className={Styles.readMore}>
        <LargeButton label="Read more" />
        <span className={Styles.minutesRead}>x minute read</span>
      </div>
    </animated.div>
  );
}

export default Card;